<template>
  <v-toolbar
    class="d-flex white justify-center custom-header"
    elevation="0"
    height="83"
  ><h3 class="green--text text--darken-4">
    {{ $t("forbidden") }}
  </h3>
  </v-toolbar>
</template>

<script>
export default {
  name: "Forbidden",
};
</script>
